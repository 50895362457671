/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Gursimran's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Gursimran Portfolio",
    type: "website",
    url: "http://gursimran.nl/",
  },
};

//Home Page
const greeting = {
  title: "Gursimran Singh",
  logo_name: "GursimranSingh",
  nickname: "Polyglot Engineer",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1DkGZ-o6u34AKF43SEDlWiWewEO0jVwjz/view?usp=sharing",
  portfolio_repository:
    "https://drive.google.com/file/d/1DkGZ-o6u34AKF43SEDlWiWewEO0jVwjz/view?usp=sharing",
  githubProfile: "https://github.com/singh-gursimran",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/singh-gursimran",
    fontAwesomeIcon: "fab fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/singh-gursimran/",
    fontAwesomeIcon: "fab fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Medium",
    link: "https://medium.com/@gursimrans1996",
    fontAwesomeIcon: "fab fa-medium", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:gursimrans1996@gmail.com",
    fontAwesomeIcon: "fa-solid fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#EA4335", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Site Reliability Engineering",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Expertise in managing high-availability systems across multi-cloud and on-premise infrastructures",
        "⚡ Skilled in designing and implementing monitoring and alerting systems for proactive incident management",
        "⚡ Proficient in incident response, post-mortem analysis, and driving improvements to prevent future outages",
        "⚡ Experience with automation tools (e.g., Ansible, Terraform) to streamline operations and infrastructure management",
        "⚡ In-depth knowledge of container orchestration platforms like Kubernetes and Docker",
        "⚡ Strong understanding of performance tuning, system scaling, and load balancing techniques",
        "⚡ Familiarity with service-level objectives (SLOs), service-level indicators (SLIs), and service-level agreements (SLAs)",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "skill-icons:gcp-light",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "devicon:azure-wordmark",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            backgroundColor: "transparent",
            // color: "#3776AB",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "skill-icons:docker",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "devicon:kubernetes",
          style: {
            backgroundColor: "transparent",
          },
        },
        // {
        //   skillName: "Cyber Security",
        //   fontAwesomeClassname: "carbon:gateway-security",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        {
          skillName: "Terraform",
          fontAwesomeClassname: "devicon:terraform-wordmark",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Ansible",
          fontAwesomeClassname: "vscode-icons:file-type-ansible",
          style: {
            backgroundColor: "transparent",
          },
        },
        // {
        //   skillName: "Puppet",
        //   fontAwesomeClassname: "logos:puppet-icon",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        // {
        //   skillName: "Networking",
        //   fontAwesomeClassname: "dashicons:networking",
        //   style: {
        //     backgroundColor: "transparent",
        //   },
        // },
        {
          skillName: "GoLang",
          fontAwesomeClassname: "skill-icons:golang",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Cloud Engineering & DevOps",
      fileName: "FullStackImg",
      skills: [
        "⚡ Advanced knowledge of cloud platforms (AWS, GCP, Azure) and designing cloud-native architectures",
        "⚡ Expertise in Infrastructure-as-Code (IaC) with tools like Terraform, CloudFormation, and Ansible",
        "⚡ Skilled in building and managing CI/CD pipelines using Jenkins, GitLab CI, or similar tools",
        "⚡ Proficient in containerization technologies like Docker and Kubernetes for application deployment and orchestration",
        "⚡ Experience in cloud migration strategies and optimizing cloud resource usage for cost efficiency",
        "⚡ Knowledge of serverless architectures and microservices-based development",
        "⚡ Expertise in version control and automation tools to ensure consistent, repeatable deployment processes",
      ],
      softwareSkills: [
        {
          skillName: "CI/CD",
          fontAwesomeClassname: "clarity:ci-cd-line",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        ,
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Helm",
          fontAwesomeClassname: "devicon:helm",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Argo",
          fontAwesomeClassname: "logos:argo-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Monitoring",
          fontAwesomeClassname: "eos-icons:monitoring",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Data Engineering & Systems Design",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Strong background in building and managing large-scale, distributed data pipelines",
        "⚡ Expertise in integrating diverse data sources and transforming data for analytics and storage",
        "⚡ Experience with real-time data processing using tools like Kafka, Apache Spark, and Flink",
        "⚡ Skilled in developing data lakes and data warehouses on cloud platforms (AWS, GCP, Azure)",
        "⚡ Knowledge of data storage solutions such as SQL/NoSQL databases, data lakes, and cloud storage",
        "⚡ Proficient in designing scalable data architectures to support business intelligence and analytics",
        "⚡ Familiarity with data modeling, ETL processes, and implementing data governance best practices",
      ],
      softwareSkills: [
        {
          skillName: "Snowflake",
          fontAwesomeClassname: "logos:snowflake-icon",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Airflow",
          fontAwesomeClassname: "logos:airflow-icon",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Hadoop",
          fontAwesomeClassname: "devicon:hadoop",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "SQL/NoSQL",
          fontAwesomeClassname: "catppuccin:folder-database",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "ML/AI",
          fontAwesomeClassname: "carbon:machine-learning-model",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "BigQuery",
          fontAwesomeClassname: "tabler:brand-google-big-query",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "dbt",
          fontAwesomeClassname: "logos:dbt",
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
        },
        {
          skillName: "Scala",
          fontAwesomeClassname: "skill-icons:scala-light",
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F79F1B",
    //   },
    //   profileLink: "https://leetcode.com/layman_brother/",
    // },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Guru Nanak Dev University",
      subtitle: "B.Tech. in Computer Science (9.5 GPA)",
      logo_path: "gndu_transparent.png",
      alt_name: "GNDU",
      duration: "2015 - 2019",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Data Engineering.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top students in University.",
      ],
      website_link: "https://online.gndu.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "Machine Learning",
    //   subtitle: "- Andrew Ng",
    //   logo_path: "stanford_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
    //   alt_name: "Stanford University",
    //   color_code: "#8C151599",
    // },
    // {
    //   title: "Deep Learning",
    //   subtitle: "- Andrew Ng",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "Data Science",
    //   subtitle: "- Alex Aklson",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Big Data",
    //   subtitle: "- Kim Akers",
    //   logo_path: "microsoft_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
    //   alt_name: "Microsoft",
    //   color_code: "#D83B0199",
    // },
    // {
    //   title: "Advanced Data Science",
    //   subtitle: "- Romeo Kienzler",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Advanced ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "DL on Tensorflow",
    //   subtitle: "- Laurence Moroney",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    // {
    //   title: "Cryptography",
    //   subtitle: "- Saurabh Mukhopadhyay",
    //   logo_path: "nptel_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
    //   alt_name: "NPTEL",
    //   color_code: "#FFBB0099",
    // },
    // {
    //   title: "Cloud Architecture",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work",
  description:
    "I have worked with many evolving startups as Software Engineer, Data Engineer, Devops and Software Architect.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Site Reliability Engineer II",
          company: "Booking.com",
          company_url: "https://www.booking.com",
          logo_path: "booking.jpeg",
          duration: "Jan 2023 - Present",
          location: "Amsterdam, The Netherlands",
          description:
            "Ensured service reliability and availability by implementing best practices and governance principles while supporting application teams. Led Root Cause Analysis (RCA) reviews and preventative initiatives for outages, managed thousands of servers across Kubernetes, bare-metal, and AWS for high-traffic applications, and developed automation tools for secure and efficient firewall policy management. Spearheaded system migrations and resolved complex service issues across network and application layers.",
          color: "#000000",
        },
        {
          title: "Site Reliability Engineer II",
          company: "Mollie",
          company_url: "https://www.mollie.com/",
          logo_path: "mollie.png",
          duration: "March 2022 – December 2022",
          location: "Amsterdam, The Netherlands",
          description:
            "Designed and developed a Private Cloud Platform, automated managed database systems, and led the migration of live on-premises systems to the cloud to enhance scalability and performance.",
          color: "#0879bf",
        },
        {
          title: "Site Reliability Engineer",
          company: "KODEYOGA",
          company_url:
            "https://www.linkedin.com/company/kodeyoga/posts/?feedView=all",
          logo_path: "kodeyoga.png",
          duration: "June 2021 – February 2022",
          location: "Pune, Maharashtra",
          description:
            "Developed a SaaS cloud platform and architected large-scale distributed systems for microservices, including web APIs. Maintained high-availability infrastructure for a critical taxation system.",
          color: "#9b1578",
        },
        {
          title: "Jr. Cloud Engineer",
          company: "HASHMAP TECH",
          company_url: "https://www.hashmap.com",
          logo_path: "hashmap.png",
          duration: "January 2019 – June 2021",
          location: "Pune, Maharashtra",
          description:
            "Designed and implemented cloud-native frameworks for AWS account management, data ingestion, and MLOps, leveraging tools like Python, Golang, Kubernetes, Terraform, and Ansible. Automated secure Hadoop clusters across AWS, GCP, and Azure, and migrated high-traffic systems to GCP with zero downtime. Built ETL pipelines and scalable data frameworks while optimizing document ingestion processes with Apache Solr. Developed custom Kubernetes operators and containerized MLOps tools for fault-tolerant deployments, improving system performance and scalability across diverse environments.",
          color: "#fc1f20",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Machine Learning Intern",
    //       company: "TikTok Inc.",
    //       company_url: "https://www.tiktok.com/en/",
    //       logo_path: "tiktok_logo.png",
    //       duration: "May 2022 - Aug 2022",
    //       location: "San Francisco, USA",
    //       description:
    //         "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Data Science Research Intern",
    //       company: "Delhivery Pvt. Ltd.",
    //       company_url: "https://www.delhivery.com/",
    //       logo_path: "delhivery_logo.png",
    //       duration: "May 2019 - Sept 2019",
    //       location: "Gurgaon, Haryana",
    //       description:
    //         "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
    //       color: "#ee3c26",
    //     },
    //     {
    //       title: "Data Science Intern",
    //       company: "Intel Indexer LLC",
    //       company_url:
    //         "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
    //       logo_path: "intel_logo.jpg",
    //       duration: "Nov 2018 - Dec 2018",
    //       location: "Work From Home",
    //       description:
    //         "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Campus Ambassador",
          company: "Tata Consultancy Services",
          company_url: "https://tcs.com/",
          logo_path: "tcs.webp",
          duration: "Jan 2018 - Jun 2019",
          location: "Guru Nanak Dev University",
          description:
            "As a Campus Ambassador, I provided academic support, conducted training sessions, coordinated test schedules, and promoted app engagement, achieving 100% participation in key activities. Additionally, I tracked progress, generated reports, and gathered feedback to enhance the program’s effectiveness, fostering a tech-driven, engaging learning environment.",
          color: "#4285F4",
        },
        {
          title: "Technical Lead",
          company: "Technical Student Society",
          company_url: "https://www.gndu.ac.in/",
          logo_path: "gndu.jpeg",
          duration: "Aug 2018 - Aug 2019",
          location: "Guru Nanak Dev University",
          description:
            "The Technical Student Society was established by students to raise awareness about technological advancements, organize events, and host hackathons to promote innovation and skill development.",
          color: "#D83B01",
        },
        // {
        //   title: "Mozilla Campus Captain",
        //   company: "Mozilla",
        //   company_url: "https://www.mozilla.org/",
        //   logo_path: "mozilla_logo.png",
        //   duration: "Oct 2019 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
        //   color: "#000000",
        // },
        // {
        //   title: "Developer Students Club Member",
        //   company: "DSC IIITDM Kurnool",
        //   company_url:
        //     "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
        //   logo_path: "dsc_logo.png",
        //   duration: "Jan 2018 - May 2020",
        //   location: "Kurnool, Andhra Pradesh",
        //   description:
        //     "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
        //   color: "#0C9D58",
        // },
        // {
        //   title: "Developer Program Member",
        //   company: "Github",
        //   company_url: "https://github.com/",
        //   logo_path: "github_logo.png",
        //   duration: "July 2019 - PRESENT",
        //   location: "Work From Home",
        //   description:
        //     "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
        //   color: "#181717",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "You can message me, I usually reply within 24 hours. I can help you with Cloud, Infrastructure, ML/AI and Data Engineering.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Amsterdam, The Netherlands",
    // locality: "San Jose",
    country: "The Netherlands",
    region: "Holland",
    // postalCode: "95129",
    // streetAddress: "Saratoga Avenue",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/TdjaiK85SC3HitoT6",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
